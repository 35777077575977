import TabsComponent from "@components/Tabs/Tabs.vue";
import TabComponent from "@components/Tabs/Tab.vue";
import QuickQuoteComponent from "@components/QuickQuote/QuickQuoteComponent.vue";

import ModalComponent from '@components/Modal/ModalComponent.vue';

import { Vue } from '@master';

const homePage = new Vue({
    el: '#home_page',
    title: "Simple Searches. Sweet Results.",
    data() {
        return {
            selectedTab: null
        }
    },
    mounted() {
        this.gaPageView('/', 'Simple Searches. Sweet Results.');
    },
    components: {
        'tabs': TabsComponent,
        'tab': TabComponent,
        'quick-quote': QuickQuoteComponent,
        'modal': ModalComponent
    }
});
